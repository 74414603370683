import Language from './Language';

const en: Language = {
    BASES_TITLE: 'Email templates',
    NEW_TEMPLATE: 'New template',
    BLANK_TEMPLATE: 'Empty template',
    DEFAULT_TEMPLATE: 'Default template',
    YOUR_TEMPLATES: 'Your templates',
    NAME: 'Name',
    CREATED_AT: 'Created at',
    UPDATED_AT: 'Date of last edition',
    TEMPLATE_OWNER: 'Owner',
    LINKED_JOURNEYS: 'Linked journeys',
    ACTIONS: 'Actions',
    NO_RESULTS: 'There are no templates created yet',
    GENERAL_ERROR_MESSAGE: 'There was an error retrieving the templates',
    SEARCH_TEMPLATES: 'Search templates',
    DELETE: 'Delete',
    DELETE_TEMPLATE_POPUP_MESSAGE:
        'Are you sure you want to delete the <strong>{templateName}</strong> template? This action is irreversible and it will not be possible to recover it.',
    DELETE_TEMPLATE: 'Delete template',
    CANCEL: 'Cancel',
    DELETE_TEMPLATE_SUCCESS_MESSAGE: 'Template successfully deleted',
    DELETE_TEMPLATE_ERROR_MESSAGE: 'Error deleting template',
    DELETE_TEMPLATE_IMPEDIMENT_POPUP_MESSAGE:
        'It is not possible to delete the <strong>{templateName}</strong> template because it has links to some cycles. Remove all links from this template and try again.',
    CLOSE_NOTICE: 'Close notice',
    RENAME: 'Rename',
    RENAME_TEMPLATE: 'Rename template',
    RENAME_TEMPLATE_SUCCESS_MESSAGE: 'Template renamed successfully.',
    RENAME_TEMPLATE_ERROR_MESSAGE: 'There was an error when trying to rename the template.',
    DUPLICATE: 'Duplicate',
    DUPLICATE_TEMPLATE: 'Duplicate template',
    DUPLICATE_TEMPLATE_SUCCESS_MESSAGE: 'Template successfully duplicated.',
    DUPLICATE_TEMPLATE_ERROR_MESSAGE: 'There was an error trying to duplicate the template.',
    HTML: 'HTML',
    DISPLAY: 'Visualization',
    SAVE: 'Save',
    SAVE_TEMPLATE: 'Save template',
    SAVE_TEMPLATE_ERROR_TOAST_MESSAGE: 'There was an error saving the template',
    SAVE_TEMPLATE_SUCCESS_TOAST_MESSAGE: 'Template has been saved successfully',
    TEMPLATE_WORKSPACE_ERROR_MESSAGE: 'There was an error loading the template',
    SEND_A_TEST: 'Send a test',
    INFORMED_A_VALID_EMAIL: 'Enter a valid e-mail address to send the template.',
    SEND_THE_EMAIL_TEST: 'Send test',
    PLACEHOLDER_EXAMPLE_EMAIL: 'example@example.com',
    TEST_EMAIL_SUCCESS_MESSAGE: 'Test e-mail sent successfully!',
    TEST_EMAIL_ERROR_MESSAGE: 'There was an error trying to send the test e-mail',
    TEMPLATE_LINKED_TO_THE_FOLLOWING_JOURNEYS: 'The <strong>{templateName}</strong> template is linked to the following journeys:',
    CLOSE: 'Close',
    THERE_WAS_ERROR_RETRIEVING_THE_LINKED_DAYS: 'There was an error retrieving the linked journeys',
    PARAMETERS_LIST: 'Parameters list',
    CREATE_TEMPLATE: 'New template',
    ERROR_WHEN_CREATING_TEMPLATE_MESSAGE: 'There was an error when trying to create a template',
    FORM_LINK_USAGE_ALERT:
        'Your current template is not making use of the <strong>:form_link</strong> parameter, which should be associated with the buttons. Please include these parameters in your code so that it is possible to use your template in the dispatch cycles.',
};

export default en;
