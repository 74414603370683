import { UserData } from '@/app/User/Models/UserData';
import { Permissions } from '@solucx/permissions';

export default class User {
    public readonly token: string;
    public readonly id: number;
    public readonly permissions: Permissions[];

    constructor(userData: UserData) {
        this.token = userData.token;
        this.id = parseInt(userData.user);
        this.permissions = atob(userData.can_access).split(',') as Permissions[];
    }

    private canAccess(access: Permissions): boolean {
        return this.permissions.indexOf(access) >= 0;
    }

    public get canManageTemplates(): boolean {
        return this.canAccess(Permissions.SETTINGS_JOURNEYS_MANAGEMENT);
    }
}
