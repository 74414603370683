import { Activity } from '../Models/Activity';
import { ActivityLog } from '../Models/ActivityLog';
import BaseService from './BaseService';

export default abstract class ActivityUserLogService extends BaseService {
    protected async postActivity(log: ActivityLog): Promise<void> {
        await this._post('/user-logs', log);
    }

    public postError(activity: Activity): void {
        console.error(`Error when collecting ${activity} log`);
    }
}
