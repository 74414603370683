import Language from './Language';

const pt: Language = {
    BASES_TITLE: 'Templates de e-mail',
    NEW_TEMPLATE: 'Novo template',
    BLANK_TEMPLATE: 'Template em branco',
    DEFAULT_TEMPLATE: 'Template padrão',
    YOUR_TEMPLATES: 'Seus templates',
    NAME: 'Nome',
    CREATED_AT: 'Data de criação',
    UPDATED_AT: 'Data da última edição',
    TEMPLATE_OWNER: 'Criador',
    LINKED_JOURNEYS: 'Jornadas vinculadas',
    ACTIONS: 'Ações',
    NO_RESULTS: 'Ainda não existem templates criados',
    GENERAL_ERROR_MESSAGE: 'Houve um erro ao recuperar os templates',
    SEARCH_TEMPLATES: 'Buscar templates',
    DELETE: 'Deletar',
    DELETE_TEMPLATE_POPUP_MESSAGE:
        'Tem certeza que deseja deletar o template <strong>{templateName}</strong>? Essa ação é irreversível e não será possível recupera-lo.',
    DELETE_TEMPLATE: 'Deletar template',
    CANCEL: 'Cancelar',
    DELETE_TEMPLATE_SUCCESS_MESSAGE: 'Template deletado com sucesso',
    DELETE_TEMPLATE_ERROR_MESSAGE: 'Erro ao deletar template',
    DELETE_TEMPLATE_IMPEDIMENT_POPUP_MESSAGE:
        'Não é possível deletar o template <strong>{templateName}</strong>, pois ele tem vínculos com alguns ciclos. Remova todos os vínculos desse template e tente novamente.',
    CLOSE_NOTICE: 'Fechar aviso',
    RENAME: 'Renomear',
    RENAME_TEMPLATE: 'Renomear template',
    RENAME_TEMPLATE_SUCCESS_MESSAGE: 'Template renomeado com sucesso.',
    RENAME_TEMPLATE_ERROR_MESSAGE: 'Houve um erro ao tentar renomear o template.',
    DUPLICATE: 'Duplicar',
    DUPLICATE_TEMPLATE: 'Duplicar template',
    DUPLICATE_TEMPLATE_SUCCESS_MESSAGE: 'Template duplicado com sucesso.',
    DUPLICATE_TEMPLATE_ERROR_MESSAGE: 'Houve um erro ao tentar duplicar o template.',
    HTML: 'HTML',
    DISPLAY: 'Visualização',
    SAVE: 'Salvar',
    SAVE_TEMPLATE: 'Salvar template',
    SAVE_TEMPLATE_ERROR_TOAST_MESSAGE: 'Houve um erro ao salvar template',
    SAVE_TEMPLATE_SUCCESS_TOAST_MESSAGE: 'Template foi salvo com sucesso',
    TEMPLATE_WORKSPACE_ERROR_MESSAGE: 'Houve um erro ao carregar o template',
    SEND_A_TEST: 'Enviar um teste',
    INFORMED_A_VALID_EMAIL: 'Insira um e-mail válido para ser feito o envio do template.',
    SEND_THE_EMAIL_TEST: 'Enviar teste',
    PLACEHOLDER_EXAMPLE_EMAIL: 'exemplo@exemplo.com.br',
    TEST_EMAIL_SUCCESS_MESSAGE: 'E-mail teste enviado com sucesso!',
    TEST_EMAIL_ERROR_MESSAGE: 'Houve um erro ao tentar enviar o e-mail teste',
    TEMPLATE_LINKED_TO_THE_FOLLOWING_JOURNEYS: 'O template <strong>{templateName}</strong> está vinculado com as seguintes jornadas:',
    CLOSE: 'Fechar',
    THERE_WAS_ERROR_RETRIEVING_THE_LINKED_DAYS: 'Houve um erro para recuperar as jornadas vinculadas',
    PARAMETERS_LIST: 'Lista de parâmetros',
    CREATE_TEMPLATE: 'Novo template',
    ERROR_WHEN_CREATING_TEMPLATE_MESSAGE: 'Houve um erro ao tentar criar um template',
    FORM_LINK_USAGE_ALERT:
        'O seu template atual não está fazendo uso do parâmetro <strong>:form_link</strong>, que deve ser associado aos botões. Por favor, inclua esses parâmetros no seu código para que seja possível utilizar seu template nos ciclos de disparo.',
};

export default pt;
