export interface LinkedJourneyDto {
    name: string;
    cycles: Array<LinkedJourneyCycleDto>;
}

export interface LinkedJourneyCycleDto {
    cycle: string;
    active: boolean;
}

export default class LinkedJourney implements LinkedJourneyDto {
    public name: string;
    public cycles: LinkedJourneyCycle[];

    constructor(journey: LinkedJourneyDto) {
        this.name = journey.name;
        this.cycles = journey.cycles.map(cycle => new LinkedJourneyCycle(cycle));
    }
}

export class LinkedJourneyCycle implements LinkedJourneyCycleDto {
    public cycle: string;
    public active: boolean;

    constructor(cycle: LinkedJourneyCycleDto) {
        this.cycle = cycle.cycle;
        this.active = cycle.active;
    }

    public get isActive(): boolean {
        return this.active === true;
    }
}
