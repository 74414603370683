import Template from './Template';
import TemplateDto from './TemplateDto';

export interface TemplateEntityDto {
    count: number;
    data: Template[];
}

export default class TemplateEntity implements TemplateEntityDto {
    public count: number;
    public data: Template[];

    constructor(count: number, data: TemplateDto[]) {
        this.count = count;
        this.data = data.map(item => new Template(item));
    }
}
