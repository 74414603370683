import { Activity } from '@/app/Common/Models/Activity';
import { ActivityLog } from '@/app/Common/Models/ActivityLog';
import ActivityUserLogService from '@/app/Common/Services/ActivityUserLogService';

export default class TemplatesLogger extends ActivityUserLogService {
    public async log(activity: Activity, userId: number): Promise<void> {
        try {
            const log: ActivityLog = { activity, userId };
            await this.postActivity(log);
        } catch (err) {
            this.postError(activity);
        }
    }

    public setUserToken(userToken: string): void {
        this.client.defaults.params = { token: userToken };
    }
}
