import Button from '@/app/Common/Components/Button.vue';
import Link from '@/app/Common/Components/Link.vue';
import ServicesInitializer from '@/app/Common/Services/ServicesInitializer';
import CustomStyler from '@/app/Config/CustomStyler';
import i18n from '@/app/Config/I18nConfig';
import InstanceService from '@/app/Instance/Services/InstanceService';
import UserService from '@/app/User/Services/UserService';
import Vue, { CreateElement } from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/styles.scss';

import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/pt-br';

export const createApp = (): Vue => {
    Vue.use(BootstrapVue);
    Vue.use(VueI18n);
    Vue.config.productionTip = false;

    const [customStyler, instanceService, userService, serviceInitializer] = [
        new CustomStyler(),
        new InstanceService(),
        new UserService(),
        new ServicesInitializer(),
    ];
    const instance = instanceService.getInstanceFromCookie();
    customStyler.mountStyle(instance.mainColor);
    const user = userService.getUserDataFromCookie(instance.slug);
    const services = serviceInitializer.getServices(instance.token, user.token);

    const app: Vue = new Vue({
        render: (h: CreateElement) => h(App),
        router,
        i18n,
        provide: {
            instance,
            user,
            customStyler,
            ...services,
        },
    }).$mount('#app');

    Vue.component('b-link', Link);
    Vue.component('b-button', Button);

    return app;
};
