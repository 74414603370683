import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: 'email-templates',
    },
    {
        path: '/email',
        name: 'email-templates',
        component: () => import(/* webpackChunkName: "email-templates" */ '@/app/EmailTemplates/EmailTemplates.vue'),
    },
    {
        path: '/email/:templateId',
        name: 'email-template-workspace',
        component: () =>
            import(/* webpackChunkName: "email-template-workspace" */ '@/app/EmailTemplateWorkspace/EmailTemplateWorkspace.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
