import TemplateDto from './TemplateDto';

export default class Template implements TemplateDto {
    public id: string;
    public employeeId: number;
    public employeeName: string;
    public name: string;
    public content: string;
    public createdAt: string;
    public updatedAt: string;
    public journeys: Array<string>;
    public isDefault: boolean;
    public hasRequiredParameters: boolean;

    constructor(template: TemplateDto) {
        this.id = template.id;
        this.employeeId = template.employeeId;
        this.employeeName = template.employeeName;
        this.name = template.name;
        this.content = template.content;
        this.createdAt = template.createdAt;
        this.updatedAt = template.updatedAt;
        this.journeys = template.journeys;
        this.isDefault = template.isDefault;
        this.hasRequiredParameters = template.hasRequiredParameters;
    }
}
