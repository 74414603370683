import BaseService from '@/app/Common/Services/BaseService';
import LinkedJourney, { LinkedJourneyDto } from '@/app/EmailTemplateWorkspace/Models/LinkedJourney';
import ResumedTemplate from '../Models/ResumedTemplate';
import Template from '../Models/Template';
import TemplateDto from '../Models/TemplateDto';
import TemplateEntity, { TemplateEntityDto } from '../Models/TemplateEntity';
import { AxiosError } from 'axios';
import { ApiError } from '../Models/ApiError';
import { TemplateMissingRequiredParametersException } from '@/app/Common/Exceptions/TemplateMissingRequiredParametersException';

export default class TemplateService extends BaseService {
    public async getTemplates(searchWord: string, limit: number = 10): Promise<TemplateEntity> {
        const entity = await this._get<TemplateEntityDto>('/email', { name: searchWord, limit });
        return new TemplateEntity(entity.count, entity.data);
    }

    public async createTemplate(template: ResumedTemplate): Promise<Template> {
        const createdTemplate = await this._post<TemplateDto>('/email', template);
        return new Template(createdTemplate);
    }

    public async deleteTemplate(templateId: string): Promise<void> {
        await this._del(`/email/${templateId}`);
    }

    public async renameTemplate(templateId: string, templateName: string): Promise<void> {
        await this._patch(`/email/${templateId}`, { name: templateName });
    }

    public async duplicateTemplate(templateId: string): Promise<void> {
        await this._post(`/email/${templateId}/duplicate`);
    }

    public async getTemplate(templateId: string): Promise<Template> {
        const template = await this._get<TemplateDto>(`/email/${templateId}`);
        return new Template(template);
    }

    public async saveTemplate(templateId: string, templateContent: string, templateName: string): Promise<void> {
        try {
            await this._put(`/email/${templateId}`, { html: templateContent, name: templateName });
        } catch (err) {
            const { error } = (err as AxiosError<ApiError>).response.data;

            if (error === 'missing_required_parameters') {
                throw new TemplateMissingRequiredParametersException();
            }
            throw new Error(error);
        }
    }

    public async sendTest(templateId: string, recipients: string[]): Promise<void> {
        await this._post(`/email/${templateId}/send-test`, { recipients });
    }

    public async getLinkedJourneys(templateId: string): Promise<LinkedJourney[]> {
        const { journeys } = await this._get<{ journeys: LinkedJourneyDto[] }>(`/email/${templateId}/journeys`);
        return journeys.map(journey => new LinkedJourney(journey));
    }
}
