/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, CancelTokenSource } from 'axios';

export default abstract class BaseService {
    constructor(protected client: AxiosInstance, instanceToken: string, userToken: string) {
        this.setReferer();
        this.setInstanceToken(instanceToken);
        this.setUserToken(userToken);
    }

    public setUserToken(userToken: string): void {
        this.client.defaults.headers.common['x-solucx-user-token'] = userToken;
    }

    public createRequestToken(): CancelTokenSource {
        return axios.CancelToken.source();
    }

    protected async _post<T>(url: string, data?: Record<string, any>): Promise<T> {
        const { data: response } = await this.client.post<T>(url, data);
        return response;
    }

    protected async _put<T>(url: string, data?: Record<string, any>): Promise<T> {
        const { data: response } = await this.client.put<T>(url, data);
        return response;
    }

    protected async _get<T>(url: string, params?: Record<string, any>): Promise<T> {
        const defaults = this.client.defaults.params;
        const { data: response } = await this.client.get<T>(url, { params: { ...defaults, ...params } });
        return response;
    }

    protected async _del<T>(url: string): Promise<T> {
        const { data: response } = await this.client.delete(url);
        return response;
    }

    protected async _patch<T>(url: string, data?: Record<string, any>): Promise<T> {
        const { data: response } = await this.client.patch<T>(url, data);
        return response;
    }

    private setInstanceToken(instanceToken: string): void {
        this.client.defaults.headers.common['x-solucx-api-key'] = instanceToken;
    }

    private setReferer(): void {
        this.client.interceptors.request.use(config => {
            config.headers['x-solucx-referer'] = window.location.href;
            return config;
        });
    }
}
